@import '../../../../cl_base/scss/theme-bootstrap';

.field-mobile-template {
  .store-locator-mobile-v1 {
    h2.store_locator {
      color: $color-light-grey-2;
    }
    .map_container {
      width: 100%;
    }
    .map_canvas {
      width: 100%;
    }
    a.button {
      background-color: $color-black;
      font-size: 0.9em;
    }
    h3.doors_results_header {
      font-size: 1.17em;
    }
    .locations_form_panel {
      .store_locator {
        font-size: 1.17em;
      }
    }
    .locations_table_panel {
      .directions {
        &.button {
          border: 1px solid $color-grey;
          color: $color-cl-light-black;
        }
      }
    }
    .zip_section_container {
      .form_element {
        &.city_container {
          border-bottom: 1px solid $color-greyish-white;
          margin-bottom: 15px;
          select.selectbox {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media #{$medium-up} {
  .store-locator-header {
    .store-locator-header-title,
    .store-locator-header-body {
      color: $color-black;
    }
  }
  .store-locator-body {
    a.button {
      background-color: $color-black;
      &:hover {
        background-color: $cr19-bg-med;
      }
    }
  }
  #store_results {
    .results-count,
    .doors_results {
      color: $color-darkest-grey;
    }
  }
  #store-search-controls {
    .sbHolder {
      height: 28.5px;
      margin-top: -1px;
    }
    .sbToggle {
      height: auto;
      background: none;
      width: 4px;
      border-#{$ldirection}: 4px solid $color-btn-inverse;
      border-#{$rdirection}: 4px solid $color-btn-inverse;
      margin: 10px;
      border-top: 6px solid $color-black;
    }
    .sbToggleOpen {
      border-top: none;
      border-bottom: 6px solid $color-black;
    }
    .sbSelector {
      height: 26px;
    }
  }
  .outer-wrap {
    .store-search-controls {
      .country_container {
        margin-block: 0;
        margin-inline: 25px 0;
      }
    }
  }
}
