@mixin button-styles {
  font-family: $helvetica-regular-font-family;
  font-size: 0.8rem;
  margin: 0;
  letter-spacing: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-black;
  border: 1px solid $color-black;
  height: 60px;
  line-height: 4.5;
}

.content {
  .product-list {
    .product {
      background-color: $cr19-bg-light;
      .product-thumb {
        .lrg_img {
          width: 100%;
          height: auto;
        }
      }
      .mpp_product {
        .product-name {
          a {
            font-family: $helvetica-light-font-family;
            letter-spacing: 0;
            line-height: 1;
            color: $color-cl-light-black;
            font-weight: 700;
          }
        }
      }
      .button-wrapper {
        a {
          @include button-styles;
          width: 100%;
          &:hover {
            background-color: $color-black;
          }
        }
      }
    }
    .desc {
      p {
        font-family: $helvetica-regular-font-family;
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;
        color: $color-cl-light-black;
        letter-spacing: 0;
        text-transform: none;
      }
      ul {
        @extend p;
        list-style: disc;
        padding-inline-start: 15px;
      }
      h3 {
        @extend p;
        font-weight: 600;
      }
      .btn_shop-now {
        @include button-styles;
        width: 150px;
      }
    }
  }
}
